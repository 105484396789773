import { post } from '@/api/api.js';
import config from "@/config/config.js";
import router from "@/router";
import global from "@/global/global.js";
export default {
  name: "UserRegister",
  data() {
    return {
      phone: '',
      userName: '',
      password: '',
      code: ''
    };
  },
  methods: {
    onBtnLogin(e) {
      const phone = this.phone.trim();
      const userName = this.userName.trim();
      const password = this.password.trim();
      if (!userName || !phone || !password) {
        this.$Message.error({
          content: `不能为空`,
          background: true
        });
        return;
      }
      const code = this.code.trim();
      post({
        path: '/api/v1/admin/register',
        data: {
          oem_id: config.OemID,
          phone: phone,
          name: userName,
          password,
          code
        }
      }).then(res => {
        if (res.data.err_code == 200 || res.data.err_code == 0) {
          //console.log(res.data);

          const userInfo = res.data.admin;
          const userData = {
            userInfo,
            token: res.data.token,
            refresh_token: res.data.refresh_token
          };
          global.UserData = userData;
          localStorage.setItem('userData', JSON.stringify(userData));
          router.replace({
            path: '/'
          });
        } else {
          this.$Message.error({
            content: res.data.err_msg
          });
        }
      }).catch(err => {
        console.error('register failed', err);
        this.$Message.error({
          content: JSON.stringify(err)
        });
      });
    }
  }
};