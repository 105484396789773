import router from "@/router";
import { canViewDeviceSummary, canViewIncomeSummary } from "./utils";

export function generateTree(menuTree) {
  let routeList = [];
  for (let item of menuTree) {
    let {
      id,
      pid,
      path,
      name,
      redirect,
      component,
      icon,
      index,
      title,
      contextmenu,
      children,
    } = item;
    let route = {
      id,
      pid,
      path,
      name,
      redirect,
      icon,
      index,
      title,
      contextmenu,
    };
    route.component = () => import(`@/views/${component}.vue`);
    if (children) {
      route.children = generateTree(children);
    }
    routeList.push(route);
  }
  return routeList;
}

export function generateRoutesFromMenu(menu) {
  // let token = localStorage.getItem('token')
  // if (!token) {
  //     return
  // }

  // let tokenData = jwt_decode(token)
  // get("/api/v1/menu", {user_id: tokenData.id})
  //     .then((resp)=>{
  //         let routers = generateTree(resp.data)
  //         routers.forEach((route)=>{
  //             router.addRoute(route)
  //         })

  //         router.addRoute({
  //             path: '*',
  //             redirect: '/404'
  //         })
  //     })
  //     .catch((e)=>{
  //         console.log(e)
  //     })

  let routers = generateTree(menu);
  routers.forEach((route) => {
    router.addRoute(route);
  });
}

export function getSideMenus(oemid, permissionSet, is_fenxiao) {
  //console.log("xx:", permissionSet, is_fenxiao);

  let menus = [];

  let summary_menus = [
    {
      id: 1,
      pid: 0,
      index: 0,
      path: "/overview",
      name: "overview",
      component: "overview/Index",
      title: "数据总览",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [],
    },
  ];

  let device_menus = [
    {
      id: 2,
      pid: 0,
      index: 1,
      path: "/device",
      name: "device",
      component: "device/Index",
      title: "节点管理",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [],
    },
  ];

  let supplier_menus = [
    {
      id: 3,
      pid: 0,
      index: 2,
      path: "/users",
      name: "users",
      component: "users/Index",
      title: "供应商管理",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [],
    },
  ];

  let bill_menus = [
    {
      id: 5,
      pid: 0,
      index: 2,
      path: "/bill",
      name: "bill",
      component: "Index",
      title: "账单结算",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [
        {
          id: 7,
          pid: 5,
          index: 1,
          path: "billdetail",
          name: "billdetail",
          component: "bill/billdetail/Index",
          title: "设备收益",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 8,
          pid: 5,
          index: 2,
          path: "weekbill",
          name: "weekbill",
          component: "bill/weekbill/Index",
          title: "供应商周账单",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        // {
        //   id: 9,
        //   pid: 5,
        //   index: 2,
        //   path: "monthbill",
        //   name: "monthbill",
        //   component: "bill/monthbill/Index",
        //   title: "供应商月账单",
        //   contextmenu: true,
        //   icon: "",
        //   redirect: "",
        //   children: [],
        // },
        {
          id: 10,
          pid: 5,
          index: 2,
          path: "withdrawal",
          name: "withdrawal",
          component: "bill/withdrawal/Index",
          title: "提现记录",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
      ],
    },
  ];

  let auth_menus = [
    {
      id: 11,
      pid: 0,
      index: 3,
      path: "/auth",
      name: "auth",
      component: "Index",
      title: "权限管理",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [
        {
          id: 12,
          pid: 11,
          index: 1,
          path: "account",
          name: "account",
          component: "auth/account/Index",
          title: "账号",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 13,
          pid: 11,
          index: 2,
          path: "role",
          name: "role",
          component: "auth/role/Index",
          title: "角色",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
      ],
    },
  ];

  let image_menus = [
    {
      id: 14,
      pid: 0,
      index: 4,
      path: "/images",
      name: "images",
      component: "images/Index",
      title: "镜像管理",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [],
    },
  ];

  let box_menus = [
    {
      id: 15,
      pid: 0,
      index: 5,
      path: "/box",
      name: "box",
      component: "Index",
      title: "盒子",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [
        {
          id: 16,
          pid: 15,
          index: 1,
          path: "boxstorage",
          name: "boxstorage",
          component: "box/storage/Index",
          title: "入库",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 17,
          pid: 15,
          index: 2,
          path: "boxdevices",
          name: "boxdevices",
          component: "box/boxdevices/Index",
          title: "盒子列表",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 18,
          pid: 15,
          index: 3,
          path: "boxbind",
          name: "boxbind",
          component: "box/boxbind/Index",
          title: "绑定",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 19,
          pid: 15,
          index: 4,
          path: "boxincomes",
          name: "boxincomes",
          component: "box/boxincomes/Index",
          title: "盒子收益",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
      ],
    },
  ];

  let system_menus = [
    {
      id: 20,
      pid: 0,
      index: 6,
      path: "/setting",
      name: "setting",
      component: "setting/Index",
      title: "系统设置",
      contextmenu: false,
      icon: "",
      redirect: "",
      children: [],
    },
  ];

  let subplatform_menus = [
    {
      id: 21,
      pid: 0,
      index: 3,
      path: "/subplatform",
      name: "subplatform",
      component: "Index",
      title: "子平台管理",
      contextmenu: true,
      icon: "",
      redirect: "",
      children: [
        {
          id: 22,
          pid: 21,
          index: 1,
          path: "subaccount",
          name: "subaccount",
          component: "subplatform/account/Index",
          title: "账号",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
        {
          id: 23,
          pid: 21,
          index: 2,
          path: "subdevice",
          name: "subdevice",
          component: "subplatform/device/Index",
          title: "设备",
          contextmenu: true,
          icon: "",
          redirect: "",
          children: [],
        },
      ],
    },
  ];

  if (
    canViewDeviceSummary(permissionSet) ||
    canViewIncomeSummary(permissionSet)
  ) {
    menus.push(...summary_menus);
  }

  menus.push(...device_menus);
  menus.push(...supplier_menus);
  menus.push(...bill_menus);
  if (!is_fenxiao) {
    menus.push(...auth_menus);
  }
  menus.push(...image_menus);
  if ((oemid == "002" || oemid == "5tfgr3") && !is_fenxiao) {
    menus.push(...box_menus);
    menus.push(...system_menus);
  }

  if (oemid == "002" && !is_fenxiao) {
    menus.push(...subplatform_menus);
  }

  return menus;
}

// let oem_5tfgr3_sideMenus = [
//   // {
//   //   id: 1,
//   //   pid: 0,
//   //   index: 0,
//   //   path: "/overview",
//   //   name: "overview",
//   //   component: "overview/Index",
//   //   title: "数据总览",
//   //   contextmenu: true,
//   //   icon: "",
//   //   redirect: "",
//   //   children: [],
//   // },
//   // {
//   //   id: 2,
//   //   pid: 0,
//   //   index: 1,
//   //   path: "/device",
//   //   name: "device",
//   //   component: "device/Index",
//   //   title: "节点管理",
//   //   contextmenu: true,
//   //   icon: "",
//   //   redirect: "",
//   //   children: [],
//   // },
//   {
//     id: 3,
//     pid: 0,
//     index: 2,
//     path: "/users",
//     name: "users",
//     component: "users/Index",
//     title: "供应商管理",
//     contextmenu: true,
//     icon: "",
//     redirect: "",
//     children: [],
//   },
//   {
//     id: 5,
//     pid: 0,
//     index: 2,
//     path: "/bill",
//     name: "bill",
//     component: "Index",
//     title: "账单结算",
//     contextmenu: true,
//     icon: "",
//     redirect: "",
//     children: [
//       // {
//       //   id: 6,
//       //   pid: 5,
//       //   index: 0,
//       //   path: "incomebill",
//       //   name: "incomebill",
//       //   component: "bill/incomebill/Index",
//       //   title: "收益账单",
//       //   contextmenu: true,
//       //   icon: "",
//       //   redirect: "",
//       //   children: [],
//       // },
//       {
//         id: 7,
//         pid: 5,
//         index: 1,
//         path: "billdetail",
//         name: "billdetail",
//         component: "bill/billdetail/Index",
//         title: "设备收益",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//       // {
//       //   id: 8,
//       //   pid: 5,
//       //   index: 2,
//       //   path: "userincome",
//       //   name: "userincome",
//       //   component: "bill/userincome/Index",
//       //   title: "供应商收益",
//       //   contextmenu: true,
//       //   icon: "",
//       //   redirect: "",
//       //   children: [],
//       // },
//       {
//         id: 8,
//         pid: 5,
//         index: 2,
//         path: "weekbill",
//         name: "weekbill",
//         component: "bill/weekbill/Index",
//         title: "供应商周账单",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//       // {
//       //   id: 9,
//       //   pid: 5,
//       //   index: 2,
//       //   path: "monthbill",
//       //   name: "monthbill",
//       //   component: "bill/monthbill/Index",
//       //   title: "供应商月账单",
//       //   contextmenu: true,
//       //   icon: "",
//       //   redirect: "",
//       //   children: [],
//       // },
//       {
//         id: 10,
//         pid: 5,
//         index: 2,
//         path: "withdrawal",
//         name: "withdrawal",
//         component: "bill/withdrawal/Index",
//         title: "提现记录",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//     ],
//   },
//   // {
//   //   id: 11,
//   //   pid: 0,
//   //   index: 3,
//   //   path: "/images",
//   //   name: "images",
//   //   component: "images/Index",
//   //   title: "镜像管理",
//   //   contextmenu: true,
//   //   icon: "",
//   //   redirect: "",
//   //   children: [],
//   // },
//   {
//     id: 12,
//     pid: 0,
//     index: 3,
//     path: "/box",
//     name: "box",
//     component: "Index",
//     title: "盒子",
//     contextmenu: true,
//     icon: "",
//     redirect: "",
//     children: [
//       {
//         id: 13,
//         pid: 12,
//         index: 0,
//         path: "boxdevices",
//         name: "boxdevices",
//         component: "box/boxdevices/Index",
//         title: "盒子列表",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//       {
//         id: 14,
//         pid: 12,
//         index: 0,
//         path: "boxbind",
//         name: "boxbind",
//         component: "box/boxbind/Index",
//         title: "绑定",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//       {
//         id: 15,
//         pid: 12,
//         index: 0,
//         path: "boxincomes",
//         name: "boxincomes",
//         component: "box/boxincomes/Index",
//         title: "盒子收益",
//         contextmenu: true,
//         icon: "",
//         redirect: "",
//         children: [],
//       },
//     ],
//   },
// ];

// export function getSideMenus(oemid) {
//   //switch (oemid) {
//   //  case "5tfgr3":
//   //    return oem_5tfgr3_sideMenus;
//   //  default:
//       return default_sideMenus;
//   //}
// }
