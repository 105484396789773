import AsideMenuItem from "@/views/aside/AsideMenuItem";
import config from "@/config/config.js";
import { generateTree, getSideMenus } from "@/utils/genRoutes.js";
export default {
  components: {
    AsideMenuItem
  },
  name: "AsideMenu",
  data() {
    return {
      theme: "light",
      menus: []
    };
  },
  methods: {
    getRoutesInfo() {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let permissionSet = new Set();
      let is_super_admin = false;
      let is_fenxiao = false;
      if (userData && userData.userInfo) {
        is_super_admin = userData.userInfo.is_super;
        is_fenxiao = userData.userInfo.fenxiao > 0;
        if (is_super_admin) {
          permissionSet = "all";
        } else {
          permissionSet = new Set(userData.userInfo.permission_vals || []);
        }
      }
      this.menus = generateTree(getSideMenus(config.OemID, permissionSet, is_fenxiao));
    }
  },
  created() {
    this.getRoutesInfo();
  }
};