import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router.js";
import config from "@/config/config.js";
import ViewUIPlus from "view-ui-plus";
import "view-ui-plus/dist/styles/viewuiplus.css";

import {
    PermissionViewImage,
    PermissionManagerImage,
    PermissionViewIncomeSummary,
    PermissionViewDeviceSummary,
    PermissionModifyDevicePrice,
    PermissionModifySupplierPrice,
    PermissionViewSupplierPhone,
    PermissionViewAdmin,
    PermissionManagerAdmin,
    PermissionViewRole,
    PermissionManagerRole,
    PermissionViewWithdrawal,
    PermissionManagerWithdrawal,
    PermissionViewSetting,
    PermissionModifySetting,
    PermissionManagerSupplier,
    PermissionViewAllDevices,
    PermissionViewAllSuppliers,
    PermissionViewOperateLog
} from "@/utils/utils.js"

let app = createApp(App);
//app.config.globalProperties.echarts = echarts;
app.config.globalProperties.AppName = config.AppName;


app.config.globalProperties.PermissionViewImageLabel = "查看镜像";
app.config.globalProperties.PermissionManagerImageLabel = "管理镜像";
app.config.globalProperties.PermissionViewIncomeSummaryLabel = "查看收益总览";
app.config.globalProperties.PermissionViewDeviceSummaryLabel = "查看设备总览";
app.config.globalProperties.PermissionModifyDevicePriceLabel = "修改设备价格和收益";
app.config.globalProperties.PermissionModifySupplierPriceLabel = "修改供应商价格和收益";
app.config.globalProperties.PermissionViewSupplierPhoneLabel = "查看供应商手机号";
app.config.globalProperties.PermissionViewAdminLabel = "查看后台账号";
app.config.globalProperties.PermissionManagerAdminLabel = "管理后台账号";
app.config.globalProperties.PermissionViewRoleLabel = "查看角色";
app.config.globalProperties.PermissionManagerRoleLabel = "管理角色";
app.config.globalProperties.PermissionViewWithdrawalLabel = "查看提现";
app.config.globalProperties.PermissionManagerWithdrawalLabel = "提现审核";
app.config.globalProperties.PermissionViewSettingLabel = "查看系统设置";
app.config.globalProperties.PermissionModifySettingLabel = "管理系统设置";
app.config.globalProperties.PermissionManagerSupplierLabel = "修改供应商信息";
app.config.globalProperties.PermissionViewAllDevicesLabel = "查看所有设备";
app.config.globalProperties.PermissionViewAllSuppliersLabel = "查看所有供应商";
app.config.globalProperties.PermissionViewOperateLogLabel = "查看操作日志";


app.config.globalProperties.getPermissionValue = function (label) {
    switch (label) {
        case this.PermissionViewImageLabel: return PermissionViewImage;
        case this.PermissionManagerImageLabel: return PermissionManagerImage;
        case this.PermissionViewIncomeSummaryLabel: return PermissionViewIncomeSummary;
        case this.PermissionViewDeviceSummaryLabel: return PermissionViewDeviceSummary;
        case this.PermissionModifyDevicePriceLabel: return PermissionModifyDevicePrice;
        case this.PermissionModifySupplierPriceLabel: return PermissionModifySupplierPrice;
        case this.PermissionViewSupplierPhoneLabel: return PermissionViewSupplierPhone;
        case this.PermissionViewAdminLabel: return PermissionViewAdmin;
        case this.PermissionManagerAdminLabel: return PermissionManagerAdmin;
        case this.PermissionViewRoleLabel: return PermissionViewRole;
        case this.PermissionManagerRoleLabel: return PermissionManagerRole;
        case this.PermissionViewWithdrawalLabel: return PermissionViewWithdrawal;
        case this.PermissionManagerWithdrawalLabel: return PermissionManagerWithdrawal;
        case this.PermissionViewSettingLabel: return PermissionViewSetting;
        case this.PermissionModifySettingLabel: return PermissionModifySetting;
        case this.PermissionManagerSupplierLabel: return PermissionManagerSupplier;
        case this.PermissionViewAllDevicesLabel: return PermissionViewAllDevices;
        case this.PermissionViewAllSuppliersLabel: return PermissionViewAllSuppliers;
        case this.PermissionViewOperateLogLabel: return PermissionViewOperateLog;
    }
    return 0;
};

app.config.globalProperties.getPermissionLabel = function (val) {
    switch (val) {
        case PermissionViewImage: return this.PermissionViewImageLabel;
        case PermissionManagerImage: return this.PermissionManagerImageLabel;
        case PermissionViewIncomeSummary: return this.PermissionViewIncomeSummaryLabel;
        case PermissionViewDeviceSummary: return this.PermissionViewDeviceSummaryLabel;
        case PermissionModifyDevicePrice: return this.PermissionModifyDevicePriceLabel;
        case PermissionModifySupplierPrice: return this.PermissionModifySupplierPriceLabel;
        case PermissionViewSupplierPhone: return this.PermissionViewSupplierPhoneLabel;
        case PermissionViewAdmin: return this.PermissionViewAdminLabel;
        case PermissionManagerAdmin: return this.PermissionManagerAdminLabel;
        case PermissionViewRole: return this.PermissionViewRoleLabel;
        case PermissionManagerRole: return this.PermissionManagerRoleLabel;
        case PermissionViewWithdrawal: return this.PermissionViewWithdrawalLabel;
        case PermissionManagerWithdrawal: return this.PermissionManagerWithdrawalLabel;
        case PermissionViewSetting: return this.PermissionViewSettingLabel;
        case PermissionModifySetting: return this.PermissionModifySettingLabel;
        case PermissionManagerSupplier: return this.PermissionManagerSupplierLabel;
        case PermissionViewAllDevices: return this.PermissionViewAllDevicesLabel;
        case PermissionViewAllSuppliers: return this.PermissionViewAllSuppliersLabel;
        case PermissionViewOperateLog: return this.PermissionViewOperateLogLabel;
    }
    return "";
};


app.use(router);
app.use(ViewUIPlus);

app.mount("#app");
