export const CmsRoleNormal = 0;
export const CmsRoleAdmin = 1;

export function formatUnixMiil(time) {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1 + "").padStart(2, "0");
  const day = (date.getDate() + "").padStart(2, "0");
  const hour = (date.getHours() + "").padStart(2, "0");
  const minute = (date.getMinutes() + "").padStart(2, "0");
  const second = (date.getSeconds() + "").padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function unix2hm(time) {
  const date = new Date(time * 1000);
  // const year = date.getFullYear()
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  const hour = (date.getHours() + "").padStart(2, "0");
  const minute = (date.getMinutes() + "").padStart(2, "0");
  //const second = date.getSeconds().padStart(2,0);

  return `${[hour, minute].join(":")}`;
}

export function getYesterday(date) {
  date = date || new Date();
  date.setDate(date.getDate() - 1);
  return date;
}

export function getYesterdayDt() {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function formatYMD(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1 + "").padStart(2, "0");
  const day = (date.getDate() + "").padStart(2, "0");
  return `${[year, month, day].join("-")}`;
}

export const PermissionViewImage = 1;
export const PermissionManagerImage = 2;
export const PermissionViewIncomeSummary = 3;
export const PermissionViewDeviceSummary = 4;
export const PermissionModifyDevicePrice = 5;
export const PermissionModifySupplierPrice = 6;
export const PermissionViewSupplierPhone = 7;
export const PermissionViewAdmin = 8;
export const PermissionManagerAdmin = 9;
export const PermissionViewRole = 10;
export const PermissionManagerRole = 11;
export const PermissionViewWithdrawal = 12;
export const PermissionManagerWithdrawal = 13;
export const PermissionViewSetting = 14;
export const PermissionModifySetting = 15;
export const PermissionManagerSupplier = 16;
export const PermissionViewAllDevices = 17;
export const PermissionViewAllSuppliers = 18;
export const PermissionViewOperateLog = 19;

//查看所有设备收益
export const PermissionViewAllDeviceIncomes = 20;

//查看所有提现
export const PermissionViewAllWithdrawOrders = 21;

//查看子平台设备
export const PermissionViewSubPlatformDevices = 22;

//查看子平台账号
export const PermissionViewSubAccounts = 23;

//管理子平台账号
export const PermissionManagerSubAccounts = 24;

export function canViewImages(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionViewImage);
}

export function canManagerImages(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionManagerImage);
}

export function canModifyDevicePrice(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionModifyDevicePrice);
}

export function canModifySupplierPrice(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionModifySupplierPrice);
}

export function canManagerSupplier(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionManagerSupplier);
}

export function canManagerAdmins(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionManagerAdmin);
}

export function canManagerSubAccounts(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionManagerSubAccounts);
}

export function canAuditWithdraw(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionManagerWithdrawal);
}

export function canViewIncomeSummary(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionViewIncomeSummary);
}

export function canViewDeviceSummary(permissionSet) {
  if (!permissionSet) return false;
  if (permissionSet === "all") return true;
  return permissionSet.has(PermissionViewDeviceSummary);
}
