import axios from "axios";
import config from "@/config/config.js";

axios.defaults.timeout = 10 * 1000;
axios.defaults.baseURL = config.ApiBaseUrl;

function request({
  method = "get",
  path,
  headers,
  params,
  data,
  token,
  json = true,
}) {
  method = method.trim().toLowerCase();
  const config = {
    method,
    url: path,
    withCredentials: false,
  };

  headers = headers || {};

  if (method === "post" || method === "patch") {
    if (!json) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      headers["Content-Type"] = "application/json;charset=UTF-8";
    }
  }

  if (token) {
    headers["Authentication"] = token;
  }

  if (!config.headers) {
    config.headers = headers;
  } else {
    for (const key in headers) {
      config.headers[key] = headers[key];
    }
  }

  if (params) {
    config.params = params;
  }

  if (data) {
    if (json) {
      config.data = JSON.stringify(data);
    } else {
      config.data = data;
    }
  }

  //console.log("config:", config);

  return axios(config);
}

export function post({ path, headers, params, data, token, json = true }) {
  return request({
    method: "post",
    path,
    headers,
    params,
    data,
    token,
    json,
  });
}
