import { createRouter, createWebHistory } from "vue-router";
import UserLogin from "@/views/UserLogin.vue";
import UserRegister from "@/views/UserRegister.vue";
import Index from "@/views/Index.vue";
import config from "@/config/config.js";
import { generateTree, getSideMenus } from "@/utils/genRoutes.js";

let indexRoute = {
  path: "/",
  name: "index",
  component: Index,
  children: [],
};

let routes = [
  indexRoute,
  {
    path: "/login",
    name: "login",
    component: UserLogin,
  },
  {
    path: "/register",
    name: "register",
    component: UserRegister,
  },
];

let menuRoutes = generateTree(getSideMenus(config.OemID, "all", false));
menuRoutes.forEach((route) => {
  if (route.pid == 0 && route.children.length == 0) {
    indexRoute.children.push(route);
  } else {
    routes.push(route);
  }
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const whiteList = ["404", "register"];
router.beforeEach((to, from) => {
  //守卫是异步解析执行，此时导航在所有守卫 resolve 完之前一直处于等待中。
  var userData = localStorage.getItem("userData");
  //check userData
  if (to.name === "login") {
    if (userData) {
      return { name: "index" };
    }
  } else {
    if (whiteList.indexOf(to.name) == -1 && !userData) {
      return { name: "login" };
    }
  }
});

export default router;
