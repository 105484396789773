import AsideMenu from "@/views/aside/AsideMenu.vue";
import router from "@/router";
export default {
  name: 'Index',
  components: {
    AsideMenu
  },
  data() {
    return {
      userData: null
    };
  },
  methods: {
    onLogout() {
      this.$Modal.confirm({
        title: "确定退出当前账号?",
        onOk: () => {
          localStorage.removeItem("userData");
          router.replace({
            path: "/login"
          });
        },
        onCancel: () => {}
      });
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }
};