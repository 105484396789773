import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67b45432"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "login"
};
const _hoisted_2 = {
  style: {
    "padding-bottom": "10px",
    "color": "rgb(54, 160, 226)"
  }
};
const _hoisted_3 = {
  class: "login-line"
};
const _hoisted_4 = {
  class: "login-line"
};
const _hoisted_5 = {
  class: "login-line"
};
const _hoisted_6 = {
  class: "login-line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input");
  const _component_Button = _resolveComponent("Button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.AppName), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_Input, {
    modelValue: $data.phone,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.phone = $event),
    placeholder: "请输入手机号"
  }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_Input, {
    modelValue: $data.userName,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.userName = $event),
    placeholder: "请输入姓名"
  }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_Input, {
    modelValue: $data.password,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.password = $event),
    type: "password",
    password: "",
    placeholder: "请输入密码"
  }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_Input, {
    modelValue: $data.code,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.code = $event),
    placeholder: "输入口令(可选)"
  }, null, 8, ["modelValue"])]), _createElementVNode("div", null, [_createVNode(_component_Button, {
    type: "primary",
    class: "login-line",
    onClick: $options.onBtnLogin
  }, {
    default: _withCtx(() => [_createTextVNode("注册")]),
    _: 1
  }, 8, ["onClick"])])]);
}