import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_AsideMenuItem = _resolveComponent("AsideMenuItem");
  const _component_Submenu = _resolveComponent("Submenu");
  const _component_Menu = _resolveComponent("Menu");
  return _openBlock(), _createBlock(_component_Menu, {
    mode: "vertical",
    "active-name": "overview",
    theme: $data.theme,
    width: "auto"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menus, route => {
      return _openBlock(), _createElementBlock(_Fragment, null, [route.children.length === 0 ? (_openBlock(), _createBlock(_component_MenuItem, {
        to: route.path,
        name: route.name,
        key: route.id
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "ios-paper"
        }), _createTextVNode(" " + _toDisplayString(route.title), 1)]),
        _: 2
      }, 1032, ["to", "name"])) : (_openBlock(), _createBlock(_component_Submenu, {
        name: route.name,
        key: route.id + 1
      }, {
        title: _withCtx(() => [_createVNode(_component_Icon, {
          type: "ios-people"
        }), _createTextVNode(" " + _toDisplayString(route.title), 1)]),
        default: _withCtx(() => [_createVNode(_component_AsideMenuItem, {
          "route-info": route.children,
          "base-path": route.path
        }, null, 8, ["route-info", "base-path"])]),
        _: 2
      }, 1032, ["name"]))], 64);
    }), 256))]),
    _: 1
  }, 8, ["theme"]);
}