import { post } from "@/api/api.js";
import config from "@/config/config.js";
import router from "@/router";
import global from "@/global/global.js";
export default {
  name: "UserLogin",
  data() {
    return {
      userName: "",
      password: ""
    };
  },
  methods: {
    onBtnLogin(e) {
      const userName = this.userName.trim();
      const password = this.password.trim();
      if (!userName || !password) {
        this.$Message.error({
          content: `${userName == "" ? "手机号" : "密码"}不能为空`,
          background: true
        });
        return;
      }
      post({
        path: "/api/v1/admin/login",
        data: {
          oem_id: config.OemID,
          phone: userName,
          password
        }
      }).then(res => {
        if (res.data.err_code == 200 || res.data.err_code == 0) {
          //console.log(res.data);

          const userInfo = res.data.admin;
          const userData = {
            userInfo,
            token: res.data.token
            //refresh_token: res.data.refresh_token,
          };

          global.UserData = userData;
          localStorage.setItem("userData", JSON.stringify(userData));
          router.replace({
            path: "/"
          });
        } else {
          this.$Message.error({
            content: res.data.err_msg
          });
        }
      }).catch(err => {
        console.error("login failed", err);
        this.$Message.error({
          content: JSON.stringify(err)
        });
      });
    }
  }
};