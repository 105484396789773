export default {
  OemID: "002",
  AppName: "恒力云",

  //OemID: "5tfgr3",
  //AppName: "中讯云",

  // OemID: "yunwangyun",
  // AppName: "云网云",

  //发布版
  ApiBaseUrl: "",

  //hengli
  //ApiBaseUrl: "https://mycloudtool.top:61001",

  //zxy
  //ApiBaseUrl: "https://mycloudtool.top:61023",

  //ywy
  //ApiBaseUrl: "https://mycloudtool.top:62313",

  //local
  //ApiBaseUrl: "http://localhost:61002",
};
