//import path from "path";

export default {
  name: "MItem",
  props: ['routeInfo', 'basePath'],
  methods: {
    getPath(routePath) {
      return this.basePath + '/' + routePath;
    }
  }
};