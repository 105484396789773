import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_MItem = _resolveComponent("MItem");
  const _component_Submenu = _resolveComponent("Submenu");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.routeInfo, route => {
    return _openBlock(), _createElementBlock(_Fragment, null, [route.children.length === 0 ? (_openBlock(), _createBlock(_component_MenuItem, {
      to: $options.getPath(route.path),
      name: route.name,
      key: route.id
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(route.title), 1)]),
      _: 2
    }, 1032, ["to", "name"])) : (_openBlock(), _createBlock(_component_Submenu, {
      name: route.name,
      key: route.id + 1
    }, {
      title: _withCtx(() => [_createTextVNode(_toDisplayString(route.title), 1)]),
      default: _withCtx(() => [_createVNode(_component_MItem, {
        "route-info": route.children,
        "base-path": $options.getPath(route.path)
      }, null, 8, ["route-info", "base-path"])]),
      _: 2
    }, 1032, ["name"]))], 64);
  }), 256))]);
}